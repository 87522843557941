<template>
  <div class="input-container">
    <input
        :value="modelValue"
        class="input__class tp-body-responsive"
        :class="{'activated-disabled': (modelValue != null && modelValue.length > 0 && disabled)}"
        :placeholder="placeholder"
        @input="onChanged"
        :disabled="disabled"
        :required="required"
        :maxlength="maxlength"
        :minlength="minlength"
        :type="type"
    />
    <label
      class="input-label tp-body-responsive"
      :class="{'activated': (modelValue != null && modelValue.length > 0)}"
      :style="{background : `linear-gradient(180deg, var(--background-firts) 0%, var(--background-firts) 45%, white 45.1%, white 100%)`}"
    >
      {{ placeholder }}
    </label>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  modelValue: String,
      placeholder: {
    type: String,
  default: "",
  },
  required: {
    type: Boolean,
  default: false,
  },
  disabled: {
    type: Boolean,
  default: false,
  },
  type: {
    type: String,
  default: "text",
  },
  bg_color: {
    type: String,
  default: "white",
  },
  maxlength: {
    type: String,
  default: "1000",
  },
  minlength: {
    type: String,
  default: "1",
  },
});
const emits = defineEmits(["update:modelValue", "inputUpdate"]);

function onChanged(e) {
  emits("update:modelValue", e.currentTarget.value);
  emits("inputUpdate");
}
</script>

<style scoped>
</style>
